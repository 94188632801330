import * as React from "react"
import { Link } from "gatsby"
import { useState } from "react"
import { PlayOutline, StarOutline, InformationCircleOutline } from 'react-ionicons'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import EndCheckout from '../../components/EndCheckout.js'
import Footer from '../../components/Footer.js'
import Logo from '../../components/Logo.js'
import { CheckoutContext } from "../../context/CheckoutContext"
import ReactPlayer from 'react-player'



const Stem = (props) => {
    return <article class="box">

        <div className="columns is-mobile content">
            <div className="column is-narrow">
                <p className="image is-96x96 stem-image">
                    <img src={props.image} alt={props.title} class="is-rounded" />
                </p>
            </div>
            <div className="column">
                <p className="title is-5">{props.title}</p>
                <p class="subtitle is-6">{props.native}</p>
                <p>{props.description}</p>
            </div>
        </div>




        {/* <hr /><div className="content">



                    <p class="title is-6">Aprūpe</p>
                    <p>{props.care}</p>

        </div> */}



    </article>
}

const Video = ({ url, captions }) => {
    return (<ReactPlayer
        url={url}
        width='100%'
        height='100%'
        // muted={true}
        config={{
            file: {
                attributes: {
                    controls: true,
                },
            },
            tracks: [
                { kind: 'captions', src: captions, label: "Instrukcijas", srcLang: 'lv', default: true }
            ]
        }}

    />)
}


// markup
const Hi = () => {

    const flowers = [
        {
            title: "Lupīnas",
            image: "https://i.imgur.com/1csrEZTb.jpg",
            native: "Eiropa",
            description: "Tās atrodamas visdažādākajās krāsās un viegi audzējami ziedi, tomēr jāuzmanās ar to izplatību, jo pastāv risks, ka tās pārmāks visus apkārt esošos augus. Ilga izturība arī grieztajiem ziediem, kas ir 5-7 dienas, ja vāzē regulāri tiek mainīts ūdens.",
        },
        {
            title: "Knifofijas - raķešpuķes",
            image: "https://i.imgur.com/9Hqmtg6b.jpg",
            native: "Āfrika",
            description: "Šie augi ir nākuši no tālās Madagaskaras un Āfrikas austrumu apgabaliem un izceļas ar savu eksotisko izskatu, zieda kāta garums vidēji ir 90-100cm. Arī vāzē ziedi ilgi saglabājas svaigi.",
        },
        {
            title: "Hiperikums",
            image: "https://i.imgur.com/exEcjs8b.jpg",
            native: "Dienvidāfrika",
            description: "Joprojām neesam pārliecināti, ka šis ir pareizais tulkojums Latviešu valodā, bet noteikti zinām, ka tās nāk no Hypericaceae, dzimtas, kas ir radniecīga asinszālei. Tām ir skaistas, krāsainas ogas un ilgs mūžs vāzē, kā dēļ to arī bieži izmanto ziedu kompozīcijās.",
            // care: "Griez stublājus 45 grādu lenķī. Izmanto istabas temperatūras ūdeni un ziedu barību. Maini ūdeni ik pēc 2 dienām. Neturi blakus augļiem.",
        },
        {
            title: "Puķuzirņi",
            image: "https://i.imgur.com/dk2pHspb.jpg",
            native: "Vidusjūras apgabals",
            description: "Debešķīgi skaisti, bet vāzes nav ilgs dzīves ilgums aptuveni 4-5 dienas. Izrādās puķuzirņi radušies saulainajā Sicīlijā. Pasaulē ir pierasts dāvināt puķuzirņus, kā pateicības vai atzinības zīmi. Interesanti, ka Francijā līgavām, puķuzirņi simbolizē veiksmi, izturību un reizē arī atvadas",
        },  

        {
            title: "Lefkoja jeb matiolas",
            image: "https://i.imgur.com/4l4p5ibb.jpg",
            native: "Vidusjūras apgabals",
            description: "Šoreiz Tev piegādājām salīdzinoši maz izziedējušas lefkojas, lai tās Tev vāzē stāvētu vēl ilgāk.",
        },
        {
            title: "Pistāciju lapas",
            image: "https://i.imgur.com/3oYQgBmb.jpg",
            native: "Centrālāzija un Tuvie Austrumi",
            description: "Pistāciju koku ģints ne tikai dod gardos riekstus, bet arī citus produktus, tostarp skaistus dekoratīvus zarus, kuru lapas lieliski papildina ziedu kompozīcijas.",
        },

    ]
    return (
        <main class="">


            <Helmet title="Instrukcija un atsauksmes" defer={false} />

            <section class="section">

                <div class="columns is-mobile">
                    <div class="column pt-0">
                        <Logo />
                    </div>
                    <div class="column is-one-third pt-0">
                        <EndCheckout />
                    </div>
                </div>
                <hr />


                <div className="container content">


                    <h1 class="title is-3 has-text-centered">👋 Tavi ziedi ir klāt!</h1>


                    {/* <p className="title is-3">Ko darīt tālāk?</p> */}

                    <div class="columns  has-text-centered">
                        <div class="column">
                            <PlayOutline height="22px" width="22px" />
                            <p>Noskaties īso instrukciju video</p>
                        </div>
                        <div class="column">
                            <InformationCircleOutline height="22px" width="22px" />
                            <p>Iepazīsties ar padomiem, lai paildzinātu prieku par ziediem</p>
                        </div>
                        <div class="column">
                            <StarOutline height="22px" width="22px" />
                            <p>Novērtē saņemtos ziedus un padalies ar draugiem</p>
                            <p>
                                <a href="https://d6f6g7elvfj.typeform.com/to/q5g4j6lP" target="_blank" class="button is-outlined">Aizpildīt novērtējumu</a>

                            </p>
                        </div>
                    </div>

                </div>
            </section>

            <section className="section" style={{ backgroundColor: '#4a4a4a' }}>
                <div className="container content">


                    <Video
                        url="https://vz-da54803c-c33.b-cdn.net/e56038ff-677f-46b1-af73-b24bb899690c/playlist.m3u8"
                        captions="/titles/016.vtt"
                    />
                </div>
            </section>


            <section className="section">
                <div className="container content">

                    <div className="columns">
                        <div className="column">


                            <h2 class="title is-3">Krāsainā vasara</h2>

                            <p>Līdz šim šis ir bijis visielākā kompozīcija ziedu skaita ziņā - Vislielākais ziedu skaits kāds ir bijis, kopā 29 ziedi un zaļumi. Ar šo kompozīciju vēlējāmies parādīt, ka ir iespējams skaisti un interesanti sakombinēt eksotiskākus ziedus (lefkojas, knifofijas utt.) un tādus, kurus var atrast arī ziedam Latvijas pļavās, kā piemēram, lupīnas (šīs konkrētās gan pagaidām vēl ir ievestas no citām valstīm, bet Jūlija sākumā arī Latvijā tās sāks ziedēt). .</p>

                            <p>Pirms sāc: </p>

                            <ul>
                                <li>Sagatavo vāzi, kuru pirms tam kārtīgi izmazgā, ielej ūdeni istabas temperatūrā un pievieno ziedu barību, 1 pulverītis uz 0,5l ūdens.</li>
                                <li>Lefkojas transportēšanas dēļ var izskatīties noliekušās. Tas nekas - atjauno griezuma vietu un ieliec tos vāzē ar ūdeni. Pēc dažām stundām tās atgūs savu formu.</li>
                            </ul>

                            <p>Kompozīcijas izveidošana:</p>

                            <ol>
                            <li>Kompozīciju izveiot šoreiz būs pavisam vienkārši, jo būs grūti izdarīt kaut ko nepareizi :)</li>
                            <li>Ielejam vāzē ūdeni istabas temperatūrā un pievienojam ziedu barību</li>
                            <li>Sākam ar zaļajiem pistāciju zariem</li>
                            <li>Turpinām ar lefkojām (rozā un lavandas krāsā) un lupīnām - kuras krāsu ziņā pamīšus izkārtojam vāzei pa perimetru un vidu</li>
                            <li>Tad izkārtojam knofofijas jeb raķešpuķes- kā acij labāk patīk, video tika likts, 2 ziedi vāzes vidū, viens malā</li>
                            <li>Tad baltās odziņas Hiperikumi</li>
                            <li>Un visbeidzot krāsainos puķuzirņus, kas šoreiz kalpo kā kompozīcijs tukšo vietu aizpildītājziedi, proti, paskatoties uz kompozīciju, kur liekas ir “tukšāks”, tur arī liec puķuziņus. Iesakām dažādās krāsas arī pamīšus likt (nevis vienu krāsu kopā), jo tas piedos kompozīcijai interesantumu un apjomu.</li>
                            <li>Atceries nomainīt vāzē ūdeni ik 2-3 dienas</li>
                            </ol>
                               

                            <p>Ķeramies pie darba! Seko video, lai redzētu darba procesu. </p>

                            <p>Lai izdodas!</p>

                            <p>Kā patīk šīs nedēļas kompozīcija? Atsūti foto Instagram! Lūdzu sniedz anonīmu novērtējumu par saņemtajiem ziediem un piegādi - <a href="https://d6f6g7elvfj.typeform.com/to/q5g4j6lP" target="_blank" class="">dot atsauksmi</a></p>


                        </div>
                        <div className="column">

                            <img src="https://i.imgur.com/N0vv3I0h.jpg" alt="Nedēļas pušķis"/>

                            <h2 class="title is-34">Aprūpe</h2>

                            <p>Seko šīm norādēm, lai ziedi saglabātos ilgāk.</p>

                            <ol>
                                <li>Atceries ūdenī vāzē mainīt katru otro dienu</li>
                                <li>Izņem ziedus un noskalo ziedu kātus</li>
                                <li>Izlej ūdenu un izmazgā vāzi tīru</li>
                                <li>Iepildi istabas temperatūras ūdeni, tajā izšķīdinot ziedu barību</li>
                                <li>Ziediem nogriez 3cm no stublāju apakšas, ar asām šķērēm griežot slīpi, 45° lenķī</li>
                            </ol>

                            <h3 class="title is-4">Par ziedu barību</h3>

                            <p>Ziedu barībai ir svarīgi ievērot norādīto barības-ūdens attiecību, lai tā nav par stipru vai vāju. Ja tā būs par koncentrētu, tad ziedi var apdegt, ja koncentrācija par vāju, tad ziediem pietrūkt vajadzīgās barības vielas un tie ātrāk novecos. Mūsu līdzi dotā barības vielas paciņa, tiek šķīdināta 0,5l ūdens</p>

                        </div>
                    </div>




                </div>
            </section>




            <section className="section" style={{ backgroundColor: '#F0E2D7' }}>
                <div className="container content">

                    <p className="title is-3">Kādus ziedus saņēmi?</p>

                    <div className="columns is-multiline">
                        {flowers.map(f => {
                            return (
                                <div className="column is-one-third">
                                    <Stem
                                        title={f.title}
                                        latin={f.latin}
                                        image={f.image}
                                        native={f.native}
                                        care={f.care}
                                        description={f.description}
                                    />

                                </div>
                            )
                        })}


                    </div>


                </div>
            </section>


            <section className="section content">
                <div className="container">

                    <div className="columns">
                        <div className="column">

                            <h2 class="title is-3">Novērtējums</h2>

                            <p>Mēs priecāsimies, ja atstāsi godīgu novērtējumu par Heyday pakalpojumu.</p>
                            <p>Tas aizņems tikai 2 minūtes.</p>

                            <a href="https://d6f6g7elvfj.typeform.com/to/q5g4j6lP" target="_blank" class="button is-primary">Aizpildīt anketu</a>


                        </div>
                        <div className="column">

                            <h2 class="title is-3">Pasaki par mums saviem draugiem</h2>

                            <p>Ja Tev saņemtie ziedi patika, padalies ar Heyday saviem draugiem. Jo vairāk cilvēki pievienojas mūsu lokam, jo plašāk varēsim sniegt ziedu prieku.</p>

                            <p>
                                <a class="button is-success" href="whatsapp://send?text=Heyday ziedu abonements https://heyday.lv?utm_source%3Dshare%26utm_medium%3Dwhatsapp%26utm_campaign%3Dfromhi" data-action="share/whatsapp/share">Pārsūtīt saiti Whatsapp</a>
                            </p>


                        </div>
                    </div>





                </div>


            </section>

            <Footer />



        </main>
    )
}

export default Hi
